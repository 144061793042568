import {MIXPANEL_KEYS} from 'analytics/mixpanel'
import {
  IT_HELPDESK,
  APP_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  MOBILE_DEVICE_MANAGEMENT,
  FOR_FOUNDERS_HR_AND_IT_ADMINS,
  BECOME_SECURITY_COMPLIANT,
  SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
  BLOG,
  ABOUT_US,
  CAREERS,
  WHY_HYBR1D,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  X_LINK,
  LINKEDIN_LINK,
} from 'v1/utils/getters'
// software icons
import Icon1 from 'v1/assets/icons/footer/logo.svg'
import Icon2 from 'v1/assets/icons/footer/logo-2.svg'
import Icon3 from 'v1/assets/icons/footer/logo-3.svg'
import Icon4 from 'v1/assets/icons/footer/logo-4.svg'
import Icon5 from 'v1/assets/icons/footer/logo-5.svg'
import SlackIcon from 'v1/assets/icons/footer/slack-logo.svg'
import DriveIcon from 'v1/assets/icons/footer/drive-logo.svg'
import MeetIcon from 'v1/assets/icons/footer/meet-logo.svg'
import MsTeamIcon from 'v1/assets/icons/footer/ms-teams-logo.svg'

// social icons
import TwitterIcon from 'v1/assets/icons/footer/twitter-icon.svg'
import FacebookIcon from 'v1/assets/icons/footer/facebook-icon.svg'
import LinkedinIcon from 'v1/assets/icons/footer/linkedin-icon.svg'
import InstagramIcon from 'v1/assets/icons/footer/instagram-icon.svg'
import FooterImage from 'v1/assets/icons/footer/footer-image.webp'

export const softwareIcons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  SlackIcon,
  DriveIcon,
  MeetIcon,
  MsTeamIcon,
]
export const footerHeaderData = {
  logo: '',
  leftText:
    'Magically simplify the way you manage your HR & IT- from payroll, benefit & expenses, to employee devices and apps',
  rightText: 'Sign up for a free resource newsletter',
}

export const footerProductsData = {
  heading: 'Products',
  list: [
    [
      {
        subList: [{name: 'Mobile Device Management', link: MOBILE_DEVICE_MANAGEMENT}],
      },
    ],
    [
      {
        subList: [{name: 'App & SaaS management', link: APP_MANAGEMENT}],
      },
    ],
    [
      {
        subList: [{name: 'Identity & Access Management', link: IDENTITY_MANAGEMENT}],
      },
    ],
    [
      {
        subList: [{name: 'Access Review', link: ''}],
      },
    ],
    [
      {
        subList: [{name: 'IT helpdesk', link: IT_HELPDESK}],
      },
    ],
    // [
    //   {
    //     title: 'ZEN Core Platform',
    //     subList: [
    //       {name: 'Employee Data base', link: ''},
    //       {name: 'Document management ', link: ''},
    //       {name: 'Workflow automation', link: ''},
    //       {name: 'Report and analytics', link: ''},
    //       {name: 'Employee self-service', link: ''},
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen HR Management',
    //     subList: [
    //       {name: 'Global payroll hub', link: ''},
    //       {name: 'Benefit Admnisitration', link: ''},
    //       {name: 'Time & Attendance', link: ''},
    //       {name: 'Time off', link: ''},
    //       {name: 'Contractor management', link: ''},
    //       {name: 'Zen PEO services', link: ''},
    //       {name: 'EOR services', link: ''},
    //       {
    //         name: 'Performance Management',
    //         flag: 'coming-later',
    //         flagText: '🚀 Coming later this year',
    //       },
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen IT Management',
    //     subList: [
    //       {name: 'Device Management', link: MOBILE_DEVICE_MANAGEMENT},
    //       {name: 'App & SaaS management', link: APP_MANAGEMENT},
    //       {name: 'Identity & Access Management', link: IDENTITY_MANAGEMENT},
    //       {name: 'Access Review', link: ''},
    //       {name: 'IT helpdesk', link: IT_HELPDESK},
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen Payroll',
    //     subList: [
    //       {name: 'Pleminary payroll', link: ''},
    //       {name: 'Global Payroll', link: ''},
    //       {name: 'US payroll', link: ''},
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen EX Employee Expearience',
    //     subList: [
    //       {name: 'Survey', flag: 'coming-later', flagText: '🚀 Coming later this year'},
    //       {name: 'AI HR companion on Slack and Teams', link: ''},
    //       {name: 'AI IT companion on Slack and Teams', link: ''},
    //       {name: 'AI Knowledge management', link: ''},
    //     ],
    //   },
    // ],
  ],
}

export const footerSolutionsData = {
  heading: 'Solutions',
  list: [
    [
      {
        subList: [{name: 'For Founders, HR and IT Admins', link: FOR_FOUNDERS_HR_AND_IT_ADMINS}],
      },
    ],
    [
      {
        subList: [{name: 'Become security compliant', link: BECOME_SECURITY_COMPLIANT}],
      },
    ],
    [
      {
        subList: [
          {
            name: 'Save costs & establish scalable processes',
            link: SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
          },
        ],
      },
    ],
  ],
}

export const footerCompanyData = {
  heading: 'Company',
  list: [
    [
      {
        subList: [{name: 'About us', link: ABOUT_US}],
      },
    ],
    [
      {
        subList: [{name: 'Blog', link: BLOG}],
      },
    ],
    [
      {
        subList: [{name: 'Career', flag: 'hiring', flagText: 'Hiring', link: CAREERS}],
      },
    ],
    [
      {
        subList: [{name: 'Why ZenAdmin', link: WHY_HYBR1D}],
      },
    ],
    // [
    //   {
    //     subList: [{name: 'Career', link: CAREERS}],
    //   },
    // ],
  ],
}

export const footerAddressData = {
  heading: 'Address',
  list: [
    [
      {
        title: 'Germany',
        subList: [{name: 'Leibnizstraße 58, 10629 Berlin, DEHRB236794B'}],
      },
      {
        title: 'India',
        subList: [{name: 'HD-128, 43, WeWork Galaxy, Ashok Nagar, Bengaluru, 560025'}],
      },
    ],
    [
      {
        title: 'Singapore',
        subList: [{name: '160 Robinson Rd #14-04, Singapore - 068914'}],
      },
      {
        title: 'Hong Kong',
        subList: [{name: 'Unit 2A 17/F, Glenealy Tower No. 1, Glenealy Central, HK - 999077'}],
      },
    ],
    [
      {
        title: 'Texas',
        subList: [{name: '25298 FM 2978 RD unit A, Tomball, TX, 77375-2504, US'}],
      },
      {
        title: 'State of Washington',
        subList: [{name: '4505 Pacific HWY E STE C-2, FIFE, WA, 98424, US'}],
      },
    ],
  ],
}

export const bottomFooterData = {
  copyrightText: '© Hybr1d Private Limited, Singapore',
  menu: [
    {
      title: 'Terms of Service',
      href: TERMS_OF_SERVICE,
      eventKey: MIXPANEL_KEYS.TERMS_OF_SERVICE,
    },
    {
      title: 'Privacy Policy',
      href: PRIVACY_POLICY,
      eventKey: MIXPANEL_KEYS.PRIVACY_POLICY,
    },
  ],
  socialMedia: [
    {name: 'twitter', icon: TwitterIcon, link: X_LINK},
    // {name: 'facebook', icon: FacebookIcon, link: ''},
    {name: 'linkedin', icon: LinkedinIcon, link: LINKEDIN_LINK},
    // {name: 'instagram', icon: InstagramIcon, link: ''},
  ],
  footerHiddenImgSrc: FooterImage,
}
