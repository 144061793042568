'use client'
import CookieConsent from 'components/cookie-consent'
import Footer  from 'v1/common-components/footer/Footer'
import * as React from 'react'

interface IPageLayout {
  children: React.ReactNode
  showExtraSection?: boolean
  showReviewSection?: boolean
  showFAQSection?: boolean
}
export default function PageLayout({
  children,
  showExtraSection,
  showReviewSection,
  showFAQSection,
}: IPageLayout) {
  return (
    <div>
      {children}
      <Footer />
      <CookieConsent />
    </div>
  )
}
