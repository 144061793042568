import clsx from 'clsx'
import VerticalCarousel from 'v1/common-components/vertical-carousel'
import {zenEXData} from 'v1/content/home-page'
import classes from './styles.module.css'

export default function ZenEX() {
  return (
    <div className={clsx(classes.bgZenEx, classes.productSection)}>
      <VerticalCarousel data={zenEXData} activeBgColor={classes.zenExActiveBgColor} activeTitleTextColorClass={classes.zenExActiveColor} imageBgColorClass={classes.bgZenEx} />
    </div>
  )
}