'use client'
import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'
import Button, {ButtonType} from 'v1/common-components/button/Button'
import SmallLogo from 'v1/assets/icons/small-logo.svg'
import ZenAdminLogo from 'v1/assets/icons/zenadmin-logo.svg'
import LoginIcon from 'v1/assets/icons/login-icon.svg'
import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import ChevronDownWhite from 'assets/icons/common/chevron-down-white.svg'
import ChevronDownBlack from 'assets/icons/common/chevron-down-black.svg'
import classes from './styles.module.css'
import {navbarLinks} from 'content/navbar'
import {DEMO_LINK, HYBRID_APP} from 'v1/utils/getters'
interface NavigationProps {
  customLinkClassName?: string
  isDarkDownArrow?: boolean
  signInBtnVariant?: ButtonType
  signInBtnCustomCss?: React.CSSProperties
  customLineClassName?: string
}

export default function Navigation({
  customLinkClassName = '',
  isDarkDownArrow = true,
  signInBtnVariant = 'secondary',
  signInBtnCustomCss,
  customLineClassName = '',
}: NavigationProps) {
  const [isWebNavOpen, setWebNavOpen] = React.useState(false)
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false)

  return (
    <>
      <nav className={classes.navbar}>
        <div className={classes.navbarInnerContainer}>
          <div className={classes.logo}>
            <Link href="/" className={classes.companyLogo}>
              <Image src={SmallLogo} alt={'logo'} height={32} />
              <Image src={ZenAdminLogo} alt={'logo'} height={16} />
            </Link>
          </div>
          <div className={classes.links}>
            {navbarLinks.map((link, index) =>
              link.dropdown ? (
                <div
                  key={index}
                  className={clsx(classes.link, classes.dropdownGroup, customLinkClassName)}
                  onMouseOver={() => setWebNavOpen(true)}
                  onMouseLeave={() => setWebNavOpen(false)}
                >
                  {link.heading}
                  {isDarkDownArrow ? (
                    <Image src={ChevronDownBlack} alt="chevron-down" height={20} width={20} />
                  ) : (
                    <Image src={ChevronDownWhite} alt="chevron-down" height={20} width={20} />
                  )}
                  {isWebNavOpen && (
                    <nav className={classes.dropdownNav}>
                      <div className={classes.dropdown}>
                        <div className={classes.dropdownLinks}>
                          {link.dropdown?.subLinks?.map((subLink, index) =>
                            subLink.external ? (
                              <a
                                key={index}
                                href={subLink.url}
                                className={clsx(classes.subLink)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {subLink.title}
                              </a>
                            ) : (
                              <Link
                                key={index}
                                href={subLink.url}
                                className={clsx(classes.subLink)}
                                onClick={e => {
                                  setWebNavOpen(false)
                                }}
                              >
                                {subLink.title}
                              </Link>
                            ),
                          )}
                        </div>
                      </div>
                    </nav>
                  )}
                </div>
              ) : link.external ? (
                <a
                  key={index}
                  href={link.url}
                  className={clsx(classes.link, customLinkClassName)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.heading}
                </a>
              ) : (
                <Link
                  key={index}
                  href={link?.url || ''}
                  className={clsx(classes.link, customLinkClassName)}
                >
                  {link.heading}
                </Link>
              ),
            )}
          </div>
          <div className={classes.actions}>
            <Link href={HYBRID_APP} target="_blank">
              <Button
                iconLeft={<Image src={LoginIcon} alt="login-icon" />}
                variant={signInBtnVariant}
                text="Login"
                style={signInBtnCustomCss}
              />
            </Link>
            <Link href={DEMO_LINK} target="_blank">
              <Button
                iconRight={
                  <span className={classes.btnIconBox}>
                    <Image src={ArrowRightIcon} alt="login-icon" width={18} height={18} />
                  </span>
                }
                variant={'primary'}
                text="Book a demo"
                style={{gap: '9px'}}
              />
            </Link>
            <div
              className={clsx(classes.hamburger, {[classes.active]: isMobileNavOpen})}
              onClick={() => setMobileNavOpen(!isMobileNavOpen)}
            >
              <div className={clsx(classes.line, classes.topLine, customLineClassName)}></div>
              <div className={clsx(classes.line, classes.middleLine, customLineClassName)}></div>
              <div className={clsx(classes.line, classes.bottomLine, customLineClassName)}></div>
            </div>
          </div>
        </div>
      </nav>

      <nav className={clsx(classes.mobileNavbar, {[classes.open]: isMobileNavOpen})}>
        <div className={clsx(classes.mobileLinks)}>
          {navbarLinks.map((link, index) =>
            link.dropdown ? (
              <div key={index} className={clsx(classes.mobileDropdownGroup)}>
                <div className={classes.mobileLink}>
                  {link.heading}
                  <Image src={ChevronDownBlack} alt="chevron-down" height={20} width={20} />
                </div>
                <nav className={classes.mobileDropdownNav}>
                  <div className={classes.mobileDropdown}>
                    <div className={classes.mobileDropdownLinks}>
                      {link.dropdown?.subLinks?.map((subLink, index) =>
                        subLink.external ? (
                          <a
                            key={index}
                            href={subLink.url}
                            className={clsx(classes.subLink)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {subLink.title}
                          </a>
                        ) : (
                          <Link
                            key={index}
                            href={subLink.url}
                            className={clsx(classes.subLink)}
                            onClick={() => setMobileNavOpen(false)}
                          >
                            {subLink.title}
                          </Link>
                        ),
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            ) : link.external ? (
              <a
                key={index}
                href={link.url}
                className={clsx(classes.mobileLink)}
                target="_blank"
                rel="noreferrer"
              >
                {link.heading}
              </a>
            ) : (
              <Link
                key={index}
                href={link?.url || ''}
                className={clsx(classes.mobileLink)}
                onClick={() => setMobileNavOpen(false)}
              >
                {link.heading}
              </Link>
            ),
          )}
        </div>
      </nav>
    </>
  )
}
