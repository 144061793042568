import {footerTestimonialData} from 'content/footer'
import Image, {StaticImageData} from 'next/image'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import {IoIosArrowRoundForward, IoIosArrowRoundBack} from 'react-icons/io'

import 'swiper/css'
import 'swiper/css/pagination'

import './styles.css'

import classes from './styles.module.css'
import clsx from 'clsx'
export default function Review({showExtraSection = false}) {
  return (
    <div className={classes.container} style={{marginTop: showExtraSection ? '240px' : '0px'}}>
      {/* <GradientText text={footerTestimonialData.topTitle} customStyle={{marginBottom: '24px'}} /> */}
      <h2 className={clsx('h3-v1', classes.header2)}>{footerTestimonialData.title}</h2>
      {/* <p className={clsx('desc', classes.desc)}>{footerTestimonialData.desc}</p> */}
      <div className={classes.reviewCardContainer}>
        <Swiper spaceBetween={10} loop={true} modules={[]} className="mySwiper">
          {footerTestimonialData.cards.map((card, idx) => (
            <SwiperSlide key={idx}>
              <ReviewCard card={card} />
            </SwiperSlide>
          ))}
          <ChangeSlide />
        </Swiper>
      </div>
    </div>
  )
}
interface ReviewCardProps {
  card: {
    quoteImg: StaticImageData
    text: string
    img: StaticImageData
    position: string
    tagText: string[]
  }
}
const ReviewCard = ({card}: ReviewCardProps) => {
  return (
    <div className={classes.cardArrowContainer}>
      <div className={classes.singleCard} key={card.position}>
        <div className={classes.imageContainer}>
          <Image className={classes.clientImage} src={card.img} height={526} alt="client" />
        </div>
        <div className={classes.detailsContainer}>
          <div>
            <Image className={classes.quoteImage} src={card.quoteImg} alt="quote" />
            <p className={classes.description}> {card.text}</p>
          </div>
          <div>
            <div className={classes.company}>
              <p className={classes.position} dangerouslySetInnerHTML={{__html: card.position}}></p>
            </div>
            <div className={classes.tags}>
              {card.tagText.map((text, idx) => (
                <p key={idx} className={classes.tag}>
                  {text}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ChangeSlide = () => {
  const swiper = useSwiper()
  return (
    <div className={classes.arrowSection}>
      <IoIosArrowRoundBack
        style={{cursor: 'pointer'}}
        size={40}
        onClick={() => swiper.slidePrev()}
        className={classes.nextBackIcon}
      />
      <IoIosArrowRoundForward
        style={{cursor: 'pointer'}}
        size={40}
        onClick={() => swiper.slideNext()}
        className={classes.nextBackIcon}
      />
    </div>
  )
}
