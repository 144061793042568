import Link from 'next/link'
import Image from 'next/image'

import Button from 'v1/common-components/button/Button'
import IconsMarquee from 'v1/common-components/icons-marquee/IconsMarquee'
import {heroData, heroMarqueeImages} from 'v1/content/home-page'
import {DEMO_LINK} from 'v1/utils/getters'

import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import classes from './styles.module.css'

export default function Hero() {
  return (
    <section className={classes.heroSection}>
      <div className={classes.innerHeroSection}>
        <div className={classes.heroTopSection}>
          <div className={classes.topInnerSection}>
            <h1 className="h1-v1">{heroData?.heading}</h1>
            <p className="paragraph1-v1">{heroData?.subHeading}</p>
          </div>
          <Link href={DEMO_LINK} target="_blank" className={classes.bookDemoBtn}>
            <Button
              text="Book a demo"
              style={{gap: '12px', borderRadius: '16px', padding: '12px 24px'}}
              iconRight={
                <span className={classes.btnIconBox}>
                  <Image src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
                </span>
              }
            />
          </Link>
        </div>
        <video autoPlay muted loop playsInline className={classes.heroVideo}>
          <source src="/assets/hero-video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div>
        <IconsMarquee
          animate={{x: ['0%', '-50%']}}
          list={[...heroMarqueeImages, ...heroMarqueeImages]}
          iconBoxClass={classes.marqueeLogoBox}
        />
      </div>
    </section>
  )
}
