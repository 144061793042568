import clsx from 'clsx'
import VerticalCarousel from 'v1/common-components/vertical-carousel'
import {zenHRData} from 'v1/content/home-page'
import classes from './styles.module.css'

export default function ZenHR() {
  return (
    <div className={clsx(classes.bgZenHr, classes.productSection)}>
      <VerticalCarousel data={zenHRData as any} activeBgColor={classes.zenHrActiveBgColor} activeTitleTextColorClass={classes.zenHrActiveColor} imageBgColorClass={classes.bgZenHr} />
    </div>
  )
}
