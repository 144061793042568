import {motion} from 'framer-motion'
import Image from 'next/image'

function IconsMarquee({
  animate,
  list,
  iconBoxClass,
  customStyles,
}: {
  animate: any
  list: any
  iconBoxClass?: string
  customStyles?: React.CSSProperties
}) {
  const repeatedIcons = [...list]

  return (
    <div style={{overflow: 'hidden', ...customStyles}}>
      <motion.div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          width: '200%',
        }}
        animate={animate}
        transition={{
          repeat: Infinity,
          duration: 60,
          ease: 'linear',
        }}
      >
        {repeatedIcons.map((icon, index) => (
          <div className={iconBoxClass} key={index}>
            <Image src={icon} alt={`${icon}-icon`} key={index} />
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default IconsMarquee
